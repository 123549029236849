<template>
  <Footer
    v-if="!state"
    id="footer"
    @ondrop.prevent
  >
    <div class="container">
      <div class="row">
        <div class="col">
          <a href="https://enterprise.orf.at/">
            <img src="@/assets/logo-orf-enterprise.png">
          </a>
          <a href="https://musikverlag.orf.at/footer/agb/"> AGB </a>
          <a href="https://enterprise.orf.at/datenschutz/"> DATENSCHUTZ </a>
          <a href="https://enterprise.orf.at/impressum">
            Impressum / Offenlegung
          </a>
          <a href="https://enterprise.orf.at/"> enterprise.ORF.at </a>
        </div>
      </div>
    </div>
  </Footer>
</template>
<script lang="ts">
import { Component, Vue } from "vue-property-decorator";

@Component
// This component represents the footer.
export default class CustomFooter extends Vue {
  // This getter checks if the Drag & Drop API is currently in use and hides the footer.
  // This is done because of a bug where the screen doesn't move down if you drag an item at the bottom of the window.
  get state(): boolean {
    return this.$store.getters.areElementsDragged;
  }
}
</script>
<style lang="scss">
#footer {
  position: fixed;
  bottom: 0px;
  /* top: 100%; */
  width: 100%;
  background-color: black;
  border-top: 1px solid #13adf0;
  /* height: 7em; */
  z-index: 10;
}
#footer .container {
  color: white;
  margin-top: 15px;
  margin-bottom: 15px;
}
#footer .container .col a img {
  margin-bottom: 0.5em;
}
#footer .container .col > a:not(:first-child) {
  margin-left: 1em;
}
#footer .container a {
  text-decoration: none;
}
</style>
