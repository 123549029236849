import {
  CartInfo,
  DropdownItem,
  ShortAccountInfo,
  TargetExportSongInfo,
  TargetExportTargetItem,
  UserResult,
  SongSearchForm,
  AccountInfo,
  MaintainancePeriod,
  MaintainancePeriodEdit,
  UserInfo,
  UserStatistics,
  UserStatistic,
  ViewStatistic,
  ViewStatistics,
} from "./classes";

// This function adds a specifc amount of zeros before a number.
const zeroPad = (num: number, places: number): string =>
  String(num).padStart(places, "0");

// This fucntion checks if the given stream is a guid.
function isGuid(text: string): boolean {
  if (text === "") {
    return false;
  }

  const guidRegEx = new RegExp(
    /^[0-9,a-z]{8}-([0-9,a-z]{4}-){3}[0-9,a-z]{12}$/i
  );
  return guidRegEx.test(text);
}

// This function maps json to a TargetExportSong object.
function mapJsonToTargetExportSong(json: string): TargetExportSongInfo {
  if (json === "") {
    throw new Error("Json can't be empty");
  }

  const res = JSON.parse(json);
  const minutes = Math.floor(res.duration / 60) ?? 0;
  const seconds = Math.floor(res.duration - minutes * 60) ?? 0;
  return new TargetExportSongInfo(
    res.kokoId,
    res.songId,
    res.title,
    `${zeroPad(minutes, 2)}:${seconds}`
  );
}

// This function maps json to a Array of TargetExportSong object.
function mapJsonToTargetExportSongs(json: string): TargetExportSongInfo[] {
  if (json === "") {
    throw new Error("Json can't be empty");
  }

  const res = JSON.parse(json);
  const songs: TargetExportSongInfo[] = [];

  res.forEach((element: any) => {
    songs.push(mapJsonToTargetExportSong(JSON.stringify(element)));
  });

  return songs;
}

// This function maps json to a DropdownItem object.
async function mapJsonToDropDown(json: string): Promise<DropdownItem> {
  if (json === "") {
    throw new Error("Json can't be empty");
  }

  const jsonObj = JSON.parse(json);
  //const key: string = jsonObj.resourceKey as string
  //return new DropdownItem(jsonObj.id, i18n.t(key).toString());
  return new DropdownItem(jsonObj.id, jsonObj.resourceKey);
}

// This function maps json to a CartInfo object.
function mapJsonToCartInfo(json: string): CartInfo {
  if (json === "") {
    throw new Error("Json can't be empty");
  }

  const jsonObj = JSON.parse(json);
  return new CartInfo(
    jsonObj.shoppingCartId,
    jsonObj.name,
    jsonObj.description,
    Funcs.toDateTime(jsonObj.creationDate),
    Funcs.toDateTime(jsonObj.lastLoadingDate),
    jsonObj.totalResults
  );
}

// This function maps json to a UserResult object.
function mapJsonToUserResult(json: string): UserResult {
  if (json === "") {
    throw new Error("Json can't be empty");
  }

  return JSON.parse(json) as UserResult;
}

// This function maps json to a list of UserResult object.
function mapJsonToUserResults(json: string): UserResult[] {
  if (json === "") {
    throw new Error("Json can't be empty");
  }

  const userArray = JSON.parse(json);

  const userRes: UserResult[] = [];

  userArray.forEach((element: any) => {
    userRes.push(mapJsonToUserResult(JSON.stringify(element)));
  });

  return userRes;
}

// This function maps json to a ShortAccountInfo object.
function mapJsonToShortAccountInfo(json: string): ShortAccountInfo {
  if (json === "") {
    throw new Error("Json can't be empty");
  }

  return JSON.parse(json) as ShortAccountInfo;
}

// This function maps json to a TargetExportTargetItem object.
function mapJsonToTargetExportTargetItem(json: string): TargetExportTargetItem {
  if (json === "") {
    throw new Error("Json can't be empty");
  }

  return JSON.parse(json) as TargetExportTargetItem;
}

// This function maps json to a list of TargetExportTargetItem object.
function mapJsonToTargetExportTargetItems(
  json: string
): TargetExportTargetItem[] {
  if (json === "") {
    throw new Error("Json can't be empty");
  }

  const targetExportItems = JSON.parse(json);

  const targetExportItemsTransformed: TargetExportTargetItem[] = [];

  targetExportItems.forEach((element: any) => {
    targetExportItemsTransformed.push(
      mapJsonToTargetExportTargetItem(JSON.stringify(element))
    );
  });

  return targetExportItemsTransformed;
}

// This function maps json to a MaintainancePeriod object.
function mapJsonToMaintainancePeriod(json: string): MaintainancePeriod {
  if (json === "") {
    throw new Error("Json can't be empty");
  }

  const el = JSON.parse(json);

  return new MaintainancePeriod(
    el.description,
    Funcs.toDateTime(el.startDateUTC),
    Funcs.toDateTime(el.endDateUTC)
  );
}

// This function maps json to a list of MaintainancePeriod objects.
function mapJsonToMaintainancePeriods(json: string): MaintainancePeriod[] {
  if (json === "") {
    throw new Error("Json can't be empty");
  }

  const mainItems = JSON.parse(json);

  const maintItemsTransformed: MaintainancePeriod[] = [];

  mainItems.forEach((element: any) => {
    maintItemsTransformed.push(
      mapJsonToMaintainancePeriod(JSON.stringify(element))
    );
  });

  return maintItemsTransformed;
}

// This function maps json to a MaintainancePeriodEdit object.
function mapJsonToMaintainancePeriodEdit(json: string): MaintainancePeriodEdit {
  if (json === "") {
    throw new Error("Json can't be empty");
  }

  const el = JSON.parse(json);

  return new MaintainancePeriodEdit(
    el.id,
    el.description,
    Funcs.toDateTime(el.startDateUTC),
    Funcs.toDateTime(el.endDateUTC)
  );
}

// This function maps json to a list of MaintainancePeriodEdit objects.
function mapJsonToMaintainancePeriodEdits(
  json: string
): MaintainancePeriodEdit[] {
  if (json === "") {
    throw new Error("Json can't be empty");
  }

  const mainItems = JSON.parse(json);

  const maintItemsTransformed: MaintainancePeriodEdit[] = [];

  mainItems.forEach((element: any) => {
    maintItemsTransformed.push(
      mapJsonToMaintainancePeriodEdit(JSON.stringify(element))
    );
  });

  return maintItemsTransformed;
}

// This function maps json to a UserInfo object.
function mapJsonToUserInfo(json: string): UserInfo {
  if (json === "") {
    throw new Error("Json can't be empty");
  }

  const el = JSON.parse(json);

  return new UserInfo(
    el.userId,
    Funcs.toDateTime(el.registrationDate),
    el.title,
    el.firstName,
    el.lastName,
    el.profession,
    el.company,
    el.email,
    el.phoneNumber,
    el.accountState
  );
}

// This function maps json to a list of UserInfo objects.
function mapJsonToUsersInfo(json: string): UserInfo[] {
  if (json === "") {
    throw new Error("Json can't be empty");
  }

  const el = JSON.parse(json);

  const users: UserInfo[] = [];

  el.forEach((element: any) => {
    users.push(mapJsonToUserInfo(JSON.stringify(element)));
  });

  return users;
}

// This function maps json to a UserStatistics object.
function mapJsonToUserStatistics(json: string): UserStatistics {
  if (json === "") {
    throw new Error("Json can't be empty");
  }

  const jsonObj = JSON.parse(json);

  const users: UserStatistic[] = [];

  jsonObj.statistics.forEach((element: any) => {
    users.push(mapJsonToUserStatistic(JSON.stringify(element)));
  });

  return new UserStatistics(users, jsonObj.results);
}

// This function maps json to a UserStatistic object.
function mapJsonToUserStatistic(json: string): UserStatistic {
  if (json === "") {
    throw new Error("Json can't be empty");
  }

  const jsonObj = JSON.parse(json);

  return new UserStatistic(
    jsonObj.userId,
    Funcs.toDateTime(jsonObj.registrationDateUnix),
    jsonObj.title,
    jsonObj.firstName,
    jsonObj.lastName,
    jsonObj.profession,
    jsonObj.company,
    jsonObj.email,
    jsonObj.phoneNumber,
    jsonObj.accountState,
    jsonObj.numberOfCarts
  );
}

// This function maps json to a ViewStatistic object.
function mapJsonToViewStatistic(json: string): ViewStatistic {
  if (json === "") {
    throw new Error("Json can't be empty");
  }

  const jsonObj = JSON.parse(json);

  return new ViewStatistic(jsonObj.timesCalled, Funcs.toDateTime(jsonObj.date));
}

// This function maps json to a ViewStatistics object.
function mapJsonToViewStatistics(json: string): ViewStatistics {
  if (json === "") {
    throw new Error("Json can't be empty");
  }

  const jsonObj = JSON.parse(json);

  const stats: ViewStatistic[] = [];

  jsonObj.statistics.forEach((element: any) => {
    stats.push(mapJsonToViewStatistic(JSON.stringify(element)));
  });

  return new ViewStatistics(
    stats,
    jsonObj.totalResults,
    jsonObj.totalTimesCalled
  );
}

// This class is used to sort the user manage table.
function mapUserSortStringToNumber(type: string): number {
  switch (type) {
    case "userId":
      return 1;
    case "registrationDate":
      return 4;
    case "firstName":
      return 2;
    case "lastName":
      return 3;
    case "accountState":
      return 5;
    default:
      return 1;
  }
}

// This class is used to sort the searchquery stat table.
// It translates the type after which to sort from a string to the number for the api request.
function mapQueryStatsSortStringToNumber(type: string): number {
  switch (type) {
    case "query":
      return 1;
    case "results":
      return 2;
    case "timesCalled":
      return 3;
    case "emotion":
      return 4;
    case "musicOccasion":
      return 5;
    case "instrument":
      return 6;
    case "musicStyle":
      return 7;
    case "date":
      return 8;
    default:
      return 1;
  }
}

// This class is used to sort the song stats table.
// It translates the type after which to sort from a string to the number for the api request.
function mapSongStatsSortStringToNumber(type: string): number {
  switch (type) {
    case "kokoId":
      return 1;
    case "title":
      return 2;
    case "album":
      return 3;
    case "duration":
      return 4;
    case "yearOfRecording":
      return 5;
    case "timesPlayed":
      return 6;
    case "timesDownloaded":
      return 7;
    default:
      return 1;
  }
}

// This class is used to sort the song stats table.
// It translates the type after which to sort from a string to the number for the api request.
function mapCartStatsSortStringToNumber(type: string): number {
  switch (type) {
    case "title":
      return 1;
    case "songCount":
      return 2;
    case "timesCalled":
      return 3;
    default:
      return 1;
  }
}

// This class is used to sort the user stats table.
// It translates the type after which to sort from a string to the number for the api request.
function mapUserStatsSortStringToNumber(type: string): number {
  switch (type) {
    case "email":
      return 1;
    case "firstName":
      return 2;
    case "lastName":
      return 3;
    case "profession":
      return 4;
    case "company":
      return 5;
    case "title":
      return 6;
    case "accountState":
      return 7;
    case "numberOfCarts":
      return 8;
    case "registrationDate":
      return 9;
    default:
      return 1;
  }
}

// This class is used to sort the dropdown stats table.
// It translates the type after which to sort from a string to the number for the api request.
function mapDropdownStatsSortStringToNumber(type: string): number {
  switch (type) {
    case "resourceSheetKey":
      return 1;
    case "category":
      return 2;
    case "timesCalled":
      return 3;
    case "localization":
      return 4;
    default:
      return 1;
  }
}

// This class is used to sort the website view stats table.
// It translates the type after which to sort from a string to the number for the api request.
function mapViewStatsSortStringToNumber(type: string): number {
  switch (type) {
    case "timesCalled":
      return 1;
    case "date":
      return 2;
    default:
      return 1;
  }
}

// This method creates a SongSearchForm object from the given parameters.
function createSongSearchForm(
  musicStyle: number,
  emotion: number,
  musicOccasion: number,
  instrument: number,
  musicMinTimeM: number | undefined,
  musicMinTimeS: number | undefined,
  musicMaxTimeM: number | undefined,
  musicMaxTimeS: number | undefined,
  search: string,
  cart: any
): SongSearchForm {
  const musicMinTimeM1 = convertToNumber(musicMinTimeM);
  const musicMinTimeS1 = convertToNumber(musicMinTimeS);
  const musicMaxTimeM1 = convertToNumber(musicMaxTimeM);
  const musicMaxTimeS1 = convertToNumber(musicMaxTimeS);

  const cartId = cart == null ? -1 : cart.shoppingCartId;

  return new SongSearchForm(
    1,
    15,
    musicStyle,
    emotion,
    musicOccasion,
    instrument,
    musicMinTimeM1,
    musicMinTimeS1,
    musicMaxTimeM1,
    musicMaxTimeS1,
    search,
    cartId
  );
}

function cloneObject<Type>(val: Type): Type {
  return JSON.parse(JSON.stringify(val));
}

function isTruthy(val: any): boolean {
  if (val) {
    return true;
  }

  return false;
}

function isEmptyOrUndefined(val: any): boolean {
  if (typeof val !== "string") {
    return true;
  }

  if (val == null || val.trim() === "") {
    return true;
  }

  return false;
}

function convertToNumber(val: any): number {
  if (isEmptyOrUndefined(val)) {
    return -1;
  }

  if (isNaN(Number(val))) {
    return -1;
  }

  return Number(val);
}

// This function is used to compare the values of two SongSearchForm objects with each other.
function compareSongSearchForms(
  form1: SongSearchForm,
  form2: SongSearchForm
): boolean {
  if (form1.emotion != form2.emotion) {
    return false;
  }

  if (form1.instrument != form2.instrument) {
    return false;
  }

  if (form1.musicMaxTimeM != form2.musicMaxTimeM) {
    return false;
  }

  if (form1.musicMaxTimeS != form2.musicMaxTimeS) {
    return false;
  }

  if (form1.musicMinTimeM != form2.musicMinTimeM) {
    return false;
  }

  if (form1.musicMinTimeS != form2.musicMinTimeS) {
    return false;
  }

  if (form1.musicOccasion != form2.musicOccasion) {
    return false;
  }

  if (form1.musicStyle != form2.musicStyle) {
    return false;
  }

  if (form1.query != form2.query) {
    return false;
  }

  return true;
}

// This function converts UTC timestamp to a Date.
function toDateTime(secs: number): Date {
  if (secs < 0) {
    throw new Error("Seconds can't be negativ!");
  }

  const t = new Date(1970, 0, 1); // Epoch
  t.setSeconds(secs);
  return t;
}

// This method checks if the SongSearchForm is default(no parameters choosen and no query).
function isSearchEmpty(form: SongSearchForm): boolean {
  if (
    form.emotion !== -1 ||
    form.instrument !== -1 ||
    form.musicOccasion !== -1 ||
    form.musicStyle !== -1 ||
    form.musicMinTimeM !== -1 ||
    form.musicMinTimeS !== -1 ||
    form.musicMaxTimeM !== -1 ||
    form.musicMaxTimeS !== -1 ||
    form.query !== ""
  ) {
    return false;
  }

  return true;
}

// This method adds an amount of hours to a date.
// This is needed because JS automaticly adds or removes hours from the date because of some timezone BS.
function addHoursToDateTime(date: Date, h: number): Date {
  const c = new Date();
  const t = new Date(date);
  c.setTime(t.getTime() + h * 60 * 60 * 1000);
  return c;
}

// This method removes an amount of hours to a date.
// This is needed because JS automaticly adds or removes hours from the date because of some timezone BS.
function removeHoursToDateTime(date: Date, h: number): Date {
  const c = new Date();
  //THIS IS SOOOOOO FUCKINBG STUPID BUT IF I DONT DO THIS THE FUCNTION DOESNT WORK!!!!!!!!!!!!!
  const t = new Date(date);
  c.setTime(t.getTime() - h * 60 * 60 * 1000);
  return c;
}

// This method adds missing dates with no visits to the stats array.
function addEmptyViewStats(
  statsArray: ViewStatistic[],
  sortAsc: boolean,
  startDate: Date,
  endDate: Date
): ViewStatistic[] {
  const statsClone = cloneObject<ViewStatistic[]>(statsArray);

  let stats = statsClone.map(
    (element: ViewStatistic) =>
      new ViewStatistic(element.timesCalled, new Date(element.date))
  );
  if (sortAsc) {
    stats = stats.sort(
      (a: ViewStatistic, b: ViewStatistic) =>
        a.date.getTime() - b.date.getTime()
    );
  } else {
    stats = stats.sort(
      (a: ViewStatistic, b: ViewStatistic) =>
        b.date.getTime() - a.date.getTime()
    );
  }

  const newStats: ViewStatistic[] = [];
  const dates: Date[] = [];
  // Add last and first date
  const firstDate = new Date(cloneObject<Date>(startDate));
  const lastDate = new Date(cloneObject<Date>(endDate));

  if (firstDate < lastDate) {
    while (firstDate < lastDate) {
      dates.push(new Date(firstDate));
      firstDate.setDate(firstDate.getDate() + 1);
    }
    dates.push(stats[stats.length - 1].date);
  } else {
    while (firstDate > lastDate) {
      dates.push(new Date(lastDate));
      lastDate.setDate(lastDate.getDate() + 1);
    }
    dates.push(stats[0].date);
  }

  dates.forEach((element: Date) => {
    if (
      stats.find(
        (stat: ViewStatistic) => stat.date.getDate() === element.getDate()
      ) != undefined
    ) {
      newStats.push(
        stats.find(
          (stat: ViewStatistic) => stat.date.getDate() === element.getDate()
        )!
      );
    } else {
      newStats.push(new ViewStatistic(0, element));
    }
  });

  return newStats;
}

const Funcs = {
  zeroPad,
  isGuid,
  mapJsonToDropDown,
  createSongSearchForm,
  isSearchEmpty,
  mapJsonToCartInfo,
  toDateTime,
  mapJsonToUserResults,
  mapJsonToShortAccountInfo,
  mapJsonToTargetExportTargetItems,
  mapJsonToTargetExportSongs,
  compareSongSearchForms,
  mapJsonToMaintainancePeriods,
  mapJsonToMaintainancePeriodEdits,
  addHoursToDateTime,
  removeHoursToDateTime,
  mapJsonToUsersInfo,
  mapUserSortStringToNumber,
  mapJsonToUserStatistics,
  mapQueryStatsSortStringToNumber,
  mapCartStatsSortStringToNumber,
  mapSongStatsSortStringToNumber,
  mapUserStatsSortStringToNumber,
  mapDropdownStatsSortStringToNumber,
  mapViewStatsSortStringToNumber,
  mapJsonToViewStatistics,
  addEmptyViewStats,
  isTruthy,
  isEmptyOrUndefined,
  convertToNumber,
  cloneObject,
};

export default Funcs;
