import Vue from "vue";
import App from "./App.vue";
import router from "./router/index";
import store from "./store/index";
import i18n from "@/plugins/i18n";
import PortalVue from "portal-vue";
import { BootstrapVue } from "bootstrap-vue";
import VueToast from "vue-toast-notification";
import VueApexCharts from "vue-apexcharts";
import VueMeta from "vue-meta";
import vSelect from "vue-select";
import DateRangePicker from 'vue2-daterange-picker';


// Adding custom css for the components.
import "bootstrap/dist/css/bootstrap.css";
import "bootstrap-vue/dist/bootstrap-vue.css";
import "@/scss/global.scss";
import "vue-toast-notification/dist/theme-default.css";
import "bootstrap-icons/font/bootstrap-icons.css";
import "vue-select/dist/vue-select.css";
import 'vue2-daterange-picker/dist/vue2-daterange-picker.css'

// Adding the website routes.
import { routeNames } from "./util/constants";
import { IRoutesNames } from "./util/interfaces";

Vue.use(VueMeta);
Vue.use(PortalVue);
Vue.use(BootstrapVue);
Vue.use(VueToast);
Vue.use(VueApexCharts);
Vue.use(DateRangePicker);
Vue.component("ApexchartVue", VueApexCharts);
Vue.component("VSelect", vSelect);
Vue.component("VDate", DateRangePicker);

Vue.filter('date', function (val: string) {

  const date = new Date(val);

  return date.toLocaleString();


});

// Adding Routenames to the Vue prototype
declare module "vue/types/vue" {
  interface Vue {
    $routesNames: IRoutesNames;
    $window: Window;
  }
}

Vue.prototype.$routesNames = routeNames;

Vue.config.productionTip = false;

Vue.prototype.$window = window;

new Vue({
  router,
  store,
  i18n,
  render: (h) => h(App),
}).$mount("#app");
