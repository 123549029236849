// This function checks if the key exists in session storage.
function exists(name: string): boolean {
  if (name === "") {
    throw new Error("Cookie name can't be empty");
  }

  const myStorage = window.sessionStorage;
  if (myStorage.getItem(name) !== null) {
    return true;
  }

  return false;
}

// This function retrieves an item from session storage with the given key.
function getItem(name: string): string | null {
  if (name === "") {
    throw new Error("Cookie name can't be empty");
  }

  const myStorage = window.sessionStorage;
  if (myStorage.getItem(name) !== null) {
    return myStorage.getItem(name);
  }

  return null;
}

// This function sets an item from session storage with the given key.
function setItem(name: string, value: string): void {
  if (name === "") {
    throw new Error("Cookie name can't be empty");
  }

  const myStorage = window.sessionStorage;
  myStorage.setItem(name, value);
}

// This function clears the sessionstorage.
function clear(): void {
  const myStorage = window.sessionStorage;
  myStorage.clear();
}

// This function removes an item from session storage with the given key.
function removeItem(name: string): void {
  if (name === "") {
    throw new Error("Cookie name can't be empty");
  }

  const myStorage = window.sessionStorage;
  myStorage.removeItem(name);
}

const SessionStorage = { exists, getItem, setItem, clear, removeItem };

export default SessionStorage;
