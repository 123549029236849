import Vue from "vue";
import Vuex from "vuex";
import { DropDownCartItem, SongInfo, TargetExportProp } from "src/util/classes";
import { CartInfo, ThemecartAdminInfo } from "@/util/classes";
import Funcs from "@/util/helperFunc";

// Register the vuex store.
Vue.use(Vuex);

// The interface of all objects stored.
export interface AppStates {
  target: null | TargetExportProp;
  draggingElements: boolean;
  currentSongs: SongInfo[];
  currentCart: null | CartInfo;
  totalResults: number;
  resultsPerPage: number;
  resultsPerPageSelector: number[];
  currentPage: number;
  currentCarts: CartInfo[];
  themeCarts: ThemecartAdminInfo[];
  cartDropDown: DropDownCartItem[];
  isAppLoading: boolean;
  showThemeCollectionPopup: boolean;
  songsDraggable: boolean;
  simSearchInput: string;
  simSearchError: string;
}

// The vuex store.
export default new Vuex.Store({
  // Contains the states of the given objects.
  state: {
    target: null,
    draggingElements: false,
    currentSongs: [],
    currentCart: null,
    totalResults: 0,
    resultsPerPage: 15,
    resultsPerPageSelector: [],
    currentPage: 1,
    currentCarts: [],
    themeCarts: [],
    cartDropDown: [],
    isAppLoading: false,
    showThemeCollectionPopup: false,
    songsDraggable: false,
    simSearchInput: '',
    simSearchError: ''
  } as AppStates,
  // Contains the getters that return the state of the objects.
  // Use this to get values.
  getters: {
    getSimSearchInput: (state) => state.simSearchInput,
    getSimSearchError: (state) => state.simSearchError,
    getTarget: (state) => state.target,
    areElementsDragged: (state) => state.draggingElements,
    getCurrentSongs: (state) => state.currentSongs,
    getSongWithId: (state) => (id: number) =>
      state.currentSongs.find((x) => x.id == id),
    areAllSongsSelected: (state) =>
      state.currentSongs.every((s) => s.isSelected),
    hideAddButtonOnAll: (state) =>
      state.currentSongs.length == 0
        ? false
        : state.currentSongs.every((s) => s.isInCart),
    getCurrentCart: (state) => state.currentCart,
    getCurrentCartDropdown: (state) =>
      state.currentCart === null
        ? null
        : { shoppingCartId: state.currentCart.shoppingCartId },
    getCurrentCartId: (state) =>
      state.currentCart === null ? -1 : state.currentCart.shoppingCartId,
    getCurrentCartName: (state) =>
      state.currentCart === null ? "" : state.currentCart?.name,
    getTotalResults: (state) => state.totalResults,
    getResultsPerPage: (state) => state.resultsPerPage,
    getResPerPageSelector: (state) => state.resultsPerPageSelector,
    getCurrentPage: (state) => state.currentPage,
    getCarts: (state) => state.currentCarts,
    getCartWithId: (state) => (id: number) =>
      state.currentCarts.find((x) => x.shoppingCartId == id),
    areAllCartsSelected: (state) =>
      state.currentCarts.every((s) => s.isSelected),
    getThemeCarts: (state) => state.themeCarts,
    getThemeCartWithId: (state) => (id: number) =>
      state.themeCarts.find((x) => x.id == id),
    areAllThemeCartsSelected: (state) =>
      state.themeCarts.every((s) => s.isSelected),
    getSelectedThemecarts: (state) =>
      state.themeCarts.filter((tc) => tc.isSelected),
    getCartDropDown: (state) => state.cartDropDown,
    getAppLoadingBool: (state) => state.isAppLoading,
    getThemeCollectionPopupBool: (state) => state.showThemeCollectionPopup,
    getSongsDragableBool: (state) => state.songsDraggable,
  },
  // These method changes the state.
  mutations: {
    SET_SIM_SEARCH_Error(state, input: string) {
      state.simSearchError = input;
    },
    SET_SIM_SEARCH_INPUT(state, input: string) {
      state.simSearchInput = input;
    },
    SET_TARGET(state, target: TargetExportProp) {
      state.target = target;
    },
    SET_DRAGGING_ELEMENT(state, isDragging: boolean) {
      state.draggingElements = isDragging;
    },
    SET_CURRENTCART(state, cart: CartInfo | null) {
      state.currentCart = cart;
    },
    SET_CURRENTSONGS(state, songs: SongInfo[]) {
      state.currentSongs = songs;
    },
    SET_ALL_SONGS_SELECTED(state, selected: boolean) {
      const cloned = Funcs.cloneObject<SongInfo[]>(state.currentSongs);
      cloned.forEach((element: SongInfo) => {
        element.isSelected = selected as boolean;
      });

      state.currentSongs = cloned;
    },
    SET_SONG(state, song: SongInfo) {
      let i = -1;
      for (let index = 0; index < state.currentSongs.length; index++) {
        if (state.currentSongs[index].id == song.id) {
          i = index;
          break;
        }
      }

      if (i == -1) {
        return;
      }

      const cloned = Funcs.cloneObject<SongInfo[]>(state.currentSongs);
      cloned[i] = song;
      state.currentSongs = cloned;
    },
    SET_TOTALRESULTS(state, res: number) {
      state.totalResults = res;
    },
    SET_RESULTS_PER_PAGE(state, res: number) {
      state.resultsPerPage = res;
    },
    SET_RESULT_PER_PAGE_SELECTOR(state, res: number[]) {
      state.resultsPerPageSelector = res;
    },
    SET_CURRENT_PAGE(state, res: number) {
      state.currentPage = res;
    },
    MOVE_SONGS(state, indexes: [number, number]) {
      const cloned: SongInfo[] = Funcs.cloneObject<SongInfo[]>(
        state.currentSongs
      );
      const source = cloned[indexes[0]];

      cloned.splice(indexes[0], 1);
      cloned.splice(indexes[1], 0, source);

      state.currentSongs = cloned;
    },
    SET_CARTS(state, carts: CartInfo[]) {
      state.currentCarts = carts;
    },
    SET_ALL_CARTS_SELECTED(state, selected: boolean) {
      const cloned = Funcs.cloneObject<CartInfo[]>(state.currentCarts);
      cloned.forEach((element: CartInfo) => {
        element.isSelected = selected;
      });

      state.currentCarts = cloned;
    },
    SET_CART(state, cart: CartInfo) {
      let i = -1;

      for (let index = 0; index < state.currentCarts.length; index++) {
        if (state.currentCarts[index].shoppingCartId == cart.shoppingCartId) {
          i = index;
          break;
        }
      }

      if (i == -1) {
        return;
      }

      const cloned = Funcs.cloneObject<CartInfo[]>(state.currentCarts);
      cloned[i] = cart;
      state.currentCarts = cloned;
    },
    SET_CART_DROPDOWN(state, elements: DropDownCartItem[]) {
      state.cartDropDown = elements;
    },
    SET_THEME_CARTS(state, carts: ThemecartAdminInfo[]) {
      state.themeCarts = carts;
    },
    SET_THEME_CART(state, cart: ThemecartAdminInfo) {
      let i = -1;

      for (let index = 0; index < state.themeCarts.length; index++) {
        if (state.themeCarts[index].id == cart.id) {
          i = index;
          break;
        }
      }

      if (i == -1) {
        return;
      }

      const cloned = Funcs.cloneObject<ThemecartAdminInfo[]>(state.themeCarts);
      cloned[i] = cart;
      state.themeCarts = cloned;
    },
    SET_ALL_THEME_CARTS_SELECTED(state, selected: boolean) {
      const cloned = Funcs.cloneObject<ThemecartAdminInfo[]>(state.themeCarts);
      cloned.forEach((element: ThemecartAdminInfo) => {
        element.isSelected = selected;
      });

      state.themeCarts = cloned;
    },
    SET_APP_LOADING_BOOL(state, isLoading: boolean) {
      state.isAppLoading = isLoading;
    },
    SET_THEMEPOPUP_BOOL(state, show: boolean) {
      state.showThemeCollectionPopup = show;
    },
    SET_SONGS_DRAGABLE(state, draggable: boolean) {
      state.songsDraggable = draggable;
    },
  },
  // These method are called to access the mutations and thus change the vuex storage from outside of the file.
  actions: {
    setSimSearchError({ commit }, input: string) {
      commit("SET_SIM_SEARCH_Error", input);
    },
    setSimSearchInput({ commit }, input: string) {
      commit("SET_SIM_SEARCH_INPUT", input);
    },
    setTarget({ commit }, target: TargetExportProp) {
      commit("SET_TARGET", target);
    },
    setDragging({ commit }, isDragging: boolean) {
      commit("SET_DRAGGING_ELEMENT", isDragging);
    },
    setCurrentCart({ commit }, cart: CartInfo | null) {
      commit("SET_CURRENTCART", cart);
    },
    setTotalResults({ commit }, totalResult: number) {
      if (totalResult < 0) {
        throw new Error("TotalResults can't be smaller than 0.");
      }

      commit("SET_TOTALRESULTS", totalResult);
    },
    setCurrentSongs({ commit }, songs: SongInfo[]) {
      commit("SET_CURRENTSONGS", songs);
    },
    setSongsSelected({ commit }, selected: boolean) {
      commit("SET_ALL_SONGS_SELECTED", selected);
    },
    setSongInSongs({ commit }, song: SongInfo) {
      commit("SET_SONG", song);
    },
    setResultsPerPage({ commit }, res: number) {
      if (res < 0) {
        throw new Error("ResultsPerPage can't be smaller than 0.");
      }

      commit("SET_RESULTS_PER_PAGE", res);
    },
    setResultsPerPageSelector({ commit }, res: number[]) {
      commit("SET_RESULT_PER_PAGE_SELECTOR", res);
    },
    setCurrentPage({ commit }, res: number) {
      if (res < 1) {
        throw new Error("CurrentPage can't be smaller than 1.");
      }

      commit("SET_CURRENT_PAGE", res);
    },
    moveItemPos({ commit }, indexes: [number, number]) {
      indexes.forEach((element) => {
        if (element < 0) {
          throw new Error("Index can't be smaller than 0.");
        }
      });

      commit("MOVE_SONGS", indexes);
    },
    setCurrentCarts({ commit }, carts: CartInfo[]) {
      commit("SET_CARTS", carts);
    },
    setCartsSelected({ commit }, selected: boolean) {
      commit("SET_ALL_CARTS_SELECTED", selected);
    },
    setCartInCarts({ commit }, cart: CartInfo) {
      commit("SET_CART", cart);
    },
    setCartDropdown({ commit }, cartDropDown: DropDownCartItem[]) {
      commit("SET_CART_DROPDOWN", cartDropDown);
    },
    setThemeCarts({ commit }, themecarts: ThemecartAdminInfo[]) {
      commit("SET_THEME_CARTS", themecarts);
    },
    setCartInThemeCarts({ commit }, themecart: ThemecartAdminInfo) {
      commit("SET_THEME_CART", themecart);
    },
    setAllThemeCartsSelected({ commit }, selected: boolean) {
      commit("SET_ALL_THEME_CARTS_SELECTED", selected);
    },
    setAppLoadingBool({ commit }, isLoading: boolean) {
      commit("SET_APP_LOADING_BOOL", isLoading);
    },
    setThemePopupBool({ commit }, show: boolean) {
      commit("SET_THEMEPOPUP_BOOL", show);
    },
    setSongsDraggableBool({ commit }, draggable: boolean) {
      commit("SET_SONGS_DRAGABLE", draggable);
    },
    resetAll({ commit }) {
      commit("SET_CURRENTCART", null);
    },
  },
  modules: {},
});
